import {Component, Input, OnInit} from '@angular/core';
import {DtMessage} from '@dt-lib/modules/pipes/dt-translate/models/translate.model';

import {IconName} from '../legacy-icon/legacy-icon.model';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss']
})
export class EmptyContentComponent implements OnInit {
  @Input() icon: IconName;
  @Input() nzIconType: string;
  @Input() title: DtMessage = 'DATES.NOTHING_HERE';
  @Input() description: DtMessage;
  @Input() height = '460px';
  @Input() borderLess = false;

  constructor() {}

  ngOnInit(): void {}
}
