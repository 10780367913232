import {Component, ContentChildren, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DtOptionComponent} from '@dt-lib/modules/components/dt-fields/components/dt-select/dt-option/dt-option.component';

@Component({
  selector: 'dt-select',
  templateUrl: './dt-select.component.html',
  styleUrl: './dt-select.component.scss'
})
export class DtSelectComponent {
  @Input() prefixIcon: string;
  @Input() control: FormControl;

  @ContentChildren(DtOptionComponent) options: DtOptionComponent[];

  constructor() {}
}
