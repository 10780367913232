import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DtLanguageSelectService {
  systemLanguages = {
    en: {
      shortTitle: 'Eng',
      title: 'English',
      icon: 'assets/dt-lib/flags/uk.svg'
    },
    ro: {
      shortTitle: 'Ro',
      title: 'Română',
      icon: 'assets/dt-lib/flags/ro.svg'
    }
  };
  systemLanguage = this.systemLanguages.en;

  constructor() {}
}
