import {Component, Input, OnInit} from '@angular/core';
import {
  DtButtonActions,
  DtButtonSizes,
  DtButtonTypes
} from '@dt-lib/modules/components/dt-button/models/dt-button.model';

@Component({
  selector: 'dt-button',
  templateUrl: './dt-button.component.html',
  styleUrls: ['./dt-button.component.scss']
})
export class DtButtonComponent implements OnInit {
  @Input() type: string = DtButtonTypes.PRIMARY;
  @Input() buttonActionType: string = DtButtonActions.BUTTON;
  @Input() disabled: boolean;
  @Input() size: string = DtButtonSizes.CUSTOM;
  @Input() skeleton = false;
  @Input() testId: string;

  constructor() {}

  ngOnInit(): void {}
}
