import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {DtLabelsService} from '@dt-lib/modules/directives/dt-labels/services/dt-labels.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtLabelLoaderGuard {
  constructor(private dtLabelsService: DtLabelsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const {production, DOMAIN, API_URL} = route.data['environment'];
    return this.dtLabelsService.initLabel(production, DOMAIN, API_URL);
  }
}
