import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';

import {HandleLinkModule} from '../../directives/handle-link/handle-link.module';
import {SectionDescriptionComponent} from './section-description.component';

@NgModule({
  declarations: [SectionDescriptionComponent],
  exports: [SectionDescriptionComponent, CommonModule],
  imports: [CommonModule, DtTranslateModule, HandleLinkModule]
})
export class SectionDescriptionModule {}
