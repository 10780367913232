import {Component, Input, OnInit} from '@angular/core';

import {BaseFieldComponent} from '../base-field/base-field.component';
import {CheckboxFieldConfig} from './models/checkbox.model';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseFieldComponent implements OnInit {
  @Input() fields: CheckboxFieldConfig[];
  @Input() direction: 'row' | 'column' = 'row';
  @Input() value: string | number;
  @Input() checked: boolean;
  @Input() background: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  onChange(field: CheckboxFieldConfig) {
    const fieldCheckedIndex = this.control.value.indexOf(field.value);
    if (fieldCheckedIndex > -1) {
      this.control.value.splice(fieldCheckedIndex, 1);
    } else {
      this.control.value.push(field.value);
    }
    this.control.markAsTouched();
    this.control.patchValue(this.control.value);
    this.valueChange.emit(this.control.value);
  }
}
