<div
  class="language-select"
  nz-dropdown
  nzTrigger="click"
  [class.language-select_small]="smallMode"
  [nzPlacement]="dropdownPlacement"
  [nzDropdownMenu]="menu"
  [(nzVisible)]="languagesDropdownVisible">
  <img *ngIf="!smallMode" alt="Flag" [src]="systemLanguage.icon" />
  <span *ngIf="smallMode">
    {{ systemLanguage.shortTitle }}
  </span>
  <span *ngIf="!smallMode">
    {{ systemLanguage.title }}
  </span>
  <i nz-icon nzType="ng-zorro:arrow-down"></i>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="language-select-menu" nz-menu>
    <li (click)="changeSystemLanguage('en')">
      <img src="assets/dt-lib/flags/uk.svg" alt="UK flag" />
      <span>English</span>
    </li>
    <li (click)="changeSystemLanguage('ro')">
      <img src="assets/dt-lib/flags/ro.svg" alt="RO flag" />
      <span>Română</span>
    </li>
  </ul>
</nz-dropdown-menu>
