<div class="select-container">
  <div *ngIf="prefixIcon" class="select-container__prefix">
    <i nz-icon [nzType]="prefixIcon"></i>
  </div>
  <nz-select
    class="select"
    [class.select_has-prefix]="!!prefixIcon"
    [nzSuffixIcon]="selectIcon"
    [formControl]="control">
    <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
  </nz-select>
  <ng-template #selectIcon>
    <i nz-icon [nzType]="'ng-zorro:arrow-down'"> </i>
  </ng-template>
</div>
