import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';

import {CheckboxComponent} from './checkbox.component';

@NgModule({
  declarations: [CheckboxComponent],
  exports: [CheckboxComponent],
  imports: [CommonModule, DtTranslateModule, ReactiveFormsModule]
})
export class CheckboxModule {}
