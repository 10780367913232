import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';

import {LegacyIconModule} from '../../../legacy-icon/legacy-icon.module';
import {InputComponent} from './input.component';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, LegacyIconModule, ReactiveFormsModule, DtTranslateModule],
  exports: [InputComponent]
})
export class InputModule {}
