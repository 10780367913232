import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {DtLabelsService} from '@dt-lib/modules/directives/dt-labels/services/dt-labels.service';
import {DtTranslateService} from '@dt-lib/modules/pipes/dt-translate/services/dt-translate.service';
import {map, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtTranslationsResolver {
  constructor(
    private dtTranslateService: DtTranslateService,
    private dtLabelsService: DtLabelsService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    let currentLanguage = this.dtLabelsService.language || 'en';
    const {lang} = route.queryParams;
    if (lang) {
      currentLanguage = lang;
    }
    if (this.dtTranslateService.predefinedLanguage) {
      currentLanguage = this.dtTranslateService.predefinedLanguage;
    }
    return this.dtTranslateService.getTranslations(currentLanguage).pipe(
      tap(() => {
        this.dtTranslateService.setTranslations(currentLanguage);
      }),
      map(() => true)
    );
  }
}
