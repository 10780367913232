import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicEnvironmentGuard {
  constructor() {}

  canActivate(): Observable<boolean> {
    if (environment.production) {
      const url = new URL(window.location.href);
      const domain = url.hostname.replace('app.', '').replace('dev.', '');
      environment.DOMAIN = `https://${domain}`;
      environment.APP_URL = `https://app.${domain}`;
      environment.API_URL = `https://api.${domain}`;
      environment.WS_URL = `wss://ws.${domain}`;
      environment.CHAT_WS_URL = `wss://chat.${domain}`;
    }
    return of(true);
  }
}
