import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NzDatePickerComponent} from 'ng-zorro-antd/date-picker';

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent implements OnInit {
  @Input() startValue: Date | null = null;
  @Input() endValue: Date | null = null;
  @Input() onlyFutureDates = false;

  @Output() endValueOnChange = new EventEmitter<Date | null>();
  @Output() startValueOnChange = new EventEmitter<Date | null>();

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;

  constructor() {}

  ngOnInit(): void {}

  disabledStartDate = (startValue: Date): boolean => {
    if (this.onlyFutureDates) {
      const today = new Date();
      return startValue <= today;
    }
    return false;
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (this.onlyFutureDates) {
      if (!this.startValue) {
        return true;
      }
      return this.startValue > endValue;
    }
    return false;
  };
}
