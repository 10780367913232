import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtMedia} from '@dt-lib/core/models/dt-media-content.model';
import {DtMediaContentService} from '@dt-lib/core/services/dt-media-content.service';

@Component({
  selector: 'dt-media-content',
  templateUrl: './dt-media-content.component.html',
  styleUrls: ['./dt-media-content.component.scss']
})
export class DtMediaContentComponent implements OnInit {
  @Input() media: DtMedia;
  @Input() muted = true;
  @Input() autoplay: boolean;
  @Input() controls: boolean;

  @Output() mediaLoad = new EventEmitter();

  constructor(private dtMediaContentService: DtMediaContentService) {}

  ngOnInit(): void {}

  handleError(): void {
    this.dtMediaContentService.handleError(this.media);
  }

  updateMediaLoad(videoElement?: HTMLVideoElement): void {
    if (videoElement && !this.autoplay) {
      videoElement.pause();
    }
    this.mediaLoad.emit();
  }
}
