import {AfterContentInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DtMapDialogService} from '@dt-lib/modules/components/dt-location-select/services/dt-map-dialog.service';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {debounceTime, from, Observable} from 'rxjs';
import LatLngLiteral = google.maps.LatLngLiteral;
import LatLng = google.maps.LatLng;
import AutocompleteService = google.maps.places.AutocompleteService;
import AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;
import Geocoder = google.maps.Geocoder;
import Map = google.maps.Map;
import QueryAutocompletePrediction = google.maps.places.QueryAutocompletePrediction;
import MapMouseEvent = google.maps.MapMouseEvent;
import MapsLibrary = google.maps.MapsLibrary;
import MarkerLibrary = google.maps.MarkerLibrary;
import GeocodingLibrary = google.maps.GeocodingLibrary;
import PlacesLibrary = google.maps.PlacesLibrary;
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import {DtLabelsService} from '@dt-lib/modules/directives/dt-labels/services/dt-labels.service';

@Component({
  selector: 'dt-map-dialog',
  templateUrl: './dt-map-dialog.component.html',
  styleUrls: ['./dt-map-dialog.component.scss']
})
export class DtMapDialogComponent implements AfterContentInit {
  @Input() timezoneCheck: boolean;

  @ViewChild('mapWrapper', {static: true}) mapWrapper: ElementRef<HTMLDivElement>;

  map: Map;
  geocoder: Geocoder;
  markerElement: AdvancedMarkerElement;

  autocompleteService: AutocompleteService;
  autocompleteResults: QueryAutocompletePrediction[] = [];
  autocompleteControl = new FormControl('');

  selectedLocation: string;
  selectedLocationCoord: {lat: number; lng: number};
  selectedLocationCountryCode: string | undefined;

  currentLocation: {lat: number; lng: number};

  constructor(
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: NzModalRef,
    private dtMapDialogService: DtMapDialogService,
    private dtLabelsService: DtLabelsService
  ) {}

  ngAfterContentInit(): void {
    this.dtMapDialogService
      .initGoogleMaps()
      .subscribe(([mapsLibrary, placesLibrary, markerLibrary, geocodingLibrary]) => {
        this.initMap(mapsLibrary);
        this.initAutocomplete(placesLibrary);
        this.initMarker(markerLibrary);
        this.initGeocoder(geocodingLibrary);
        this.getCurrentLocation();
      });
  }

  private initMap(mapsLibrary: MapsLibrary): void {
    this.ngZone.runOutsideAngular(() => {
      this.map = new mapsLibrary.Map(this.mapWrapper.nativeElement, {
        mapId: 'google-map',
        center: this.getCenterCoordinates(),
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        zoom: 8,
        gestureHandling: 'greedy'
      });
    });
  }

  private getCenterCoordinates(): {lat: number; lng: number} {
    if (this.dtLabelsService.isRomanianLabel) {
      return {lat: 44.4396, lng: 26.0963};
    }
    return {lat: 51.5098, lng: -0.118};
  }

  private initAutocomplete(placesLibrary: PlacesLibrary): void {
    this.autocompleteService = new placesLibrary.AutocompleteService();
    this.handleAutocompleteChanges();
  }

  private handleAutocompleteChanges(): void {
    this.autocompleteControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      if (value) {
        this.autocompleteService.getQueryPredictions({input: value}, (res: QueryAutocompletePrediction[]) => {
          this.autocompleteResults = res;
          this.changeDetectorRef.detectChanges();
        });
      } else {
        this.autocompleteResults = [];
      }
    });
  }

  selectAutocompleteOption(item: QueryAutocompletePrediction) {
    this.geocoder.geocode(
      {
        address: item.description
      },
      ([location], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.setMarker(location.geometry.location.lat(), location.geometry.location.lng());
          this.autocompleteResults = [];
        }
      }
    );
  }

  clearAutoComplete(): void {
    this.autocompleteControl.patchValue('');
  }

  private initMarker(markerLibrary: MarkerLibrary): void {
    this.markerElement = new markerLibrary.AdvancedMarkerElement({
      map: this.map
    });
    this.map.addListener('click', (event: MapMouseEvent) => {
      this.setMarker(event.latLng.lat(), event.latLng.lng());
    });
  }

  private setMarker(lat: number, lng: number): void {
    this.map.setCenter({
      lat,
      lng
    });
    this.markerElement.position = {lat, lng};
    this.updateSelectedLocation(this.markerElement.position);
  }

  private updateSelectedLocation(latLng: LatLng | LatLngLiteral): void {
    const lat = latLng.lat;
    const lng = latLng.lng;
    this.geocoder.geocode(
      {
        location: latLng,
        language: 'en'
      },
      ([location], status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.selectedLocation = this.getLocationAddressName(location);
          this.selectedLocationCoord = {lat: lat as number, lng: lng as number};
          this.selectedLocationCountryCode = location.address_components.find((addressComponent) => {
            return addressComponent.types.includes('country');
          })?.short_name;
          this.changeDetectorRef.detectChanges();
        }
      }
    );
  }

  private getLocationAddressName(location: GeocoderResult): string {
    const necessaryTypes = ['locality', 'postal_town', 'administrative_area_level_1', 'country'];
    return location.address_components
      .filter((addressComponent: GeocoderAddressComponent) => {
        return addressComponent.types.filter((type: string) => {
          return necessaryTypes.includes(type);
        }).length;
      })
      .map((addressComponent: GeocoderAddressComponent) => {
        return addressComponent.long_name;
      })
      .join(', ');
  }

  private initGeocoder(geocodingLibrary: GeocodingLibrary): void {
    this.geocoder = new geocodingLibrary.Geocoder();
  }

  private getCurrentLocation(): void {
    navigator.geolocation.getCurrentPosition((success: GeolocationPosition) => {
      this.currentLocation = {
        lat: success.coords.latitude,
        lng: success.coords.longitude
      };
    });
  }

  setCurrentLocationMarker(): void {
    this.setMarker(this.currentLocation.lat, this.currentLocation.lng);
  }

  selectLocation(): void {
    const location = {
      name: this.selectedLocation,
      coord: this.selectedLocationCoord,
      countryCode: this.selectedLocationCountryCode
    };
    if (this.timezoneCheck) {
      this.getLocationTimezone(this.selectedLocationCoord.lat, this.selectedLocationCoord.lng).subscribe((result) => {
        if (result) {
          const totalOffset = result.rawOffset + result.dstOffset;
          this.dialogRef.close({...location, timezoneOffset: totalOffset});
        }
      });
    } else {
      this.dialogRef.close(location);
    }
  }

  private getLocationTimezone(lat: number, lng: number): Observable<any> {
    const timestamp = Math.floor(Date.now() / 1000);
    const apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${this.dtMapDialogService.googleMapsApiKey}`;
    return from(
      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            return null;
          }
          return response.json();
        })
        .catch(() => {
          return null;
        })
    );
  }

  clearSelectedLocation() {
    this.selectedLocation = null;
    this.selectedLocationCoord = null;
    this.markerElement.position = null;
  }
}
