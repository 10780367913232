<div *ngIf="profile" class="profile-alias">
  <div class="profile-alias__info" (click)="emitRedirectToProfile()">
    <img
      *ngIf="!hideAvatar"
      alt="avatar"
      onerror="this.onerror=null;this.src='assets/images/avatars/default-avatar.png'"
      [src]="(profile.avatar | dtDefaultAvatar).url" />
    <div>
      <div class="profile-alias__name">
        <span>
          {{ profile.username | dtTranslate }}
        </span>
        <i *ngIf="profile?.hasPremiumSubscription" nz-icon nzType="ng-zorro:crown" class="premium-icon"></i>
        <i *ngIf="profile?.isVerified" nz-icon nzType="ng-zorro:verified" class="verified-icon"></i>
        <i *ngIf="profile?.isOnline" nz-icon nzType="ng-zorro:circle" class="online-icon"></i>
      </div>
      <div *ngIf="!onlyTitle" class="profile-alias__description">
        {{ description || profile?.statusHeading }}
      </div>
    </div>
  </div>
  <ng-content select=".profile-alias-actions"></ng-content>
</div>
<div *ngIf="!profile" class="profile-alias" [class.skeleton]="!profile">
  <div class="profile-alias__info">
    <div *ngIf="!hideAvatar" class="profile-alias__image"></div>
    <div>
      <div class="profile-alias__name"></div>
      <div *ngIf="!onlyTitle" class="profile-alias__description"></div>
    </div>
  </div>
</div>
