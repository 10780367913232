import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DtButtonModule} from '@dt-lib/modules/components/dt-button/dt-button.module';
import {DtDialogModule} from '@dt-lib/modules/components/dt-dialogs/dt-dialog.module';
import {DtFieldsModule} from '@dt-lib/modules/components/dt-fields/dt-fields.module';
import {DtLocationSelectModule} from '@dt-lib/modules/components/dt-location-select/dt-location-select.module';
import {DtMediaContentModule} from '@dt-lib/modules/components/dt-media-content/dt-media-content.module';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzSliderModule} from 'ng-zorro-antd/slider';

import {MeasurementModule} from '../../pipes/measurement/measurement.module';
import {EmptyContentModule} from '../empty-content/empty-content.module';
import {CheckboxModule} from '../fields/components/checkbox/checkbox.module';
import {DatepickerRangeModule} from '../fields/components/datepicker-range/datepicker-range.module';
import {InputModule} from '../fields/components/input/input.module';
import {RadioModule} from '../fields/components/radio/radio.module';
import {TextareaModule} from '../fields/components/textarea/textarea.module';
import {LegacyIconModule} from '../legacy-icon/legacy-icon.module';
import {ProfileAliasModule} from '../profiles-previews/profile-alias/profile-alias.module';
import {SectionDescriptionModule} from '../section-description/section-description.module';
import {ConfirmDialogModule} from './components/confirm-dialog/confirm-dialog.module';
import {FilterDialogComponent} from './components/filter-dialog/filter-dialog.component';
import {ShareDialogComponent} from './components/share-dialog/share-dialog.component';

@NgModule({
  declarations: [ShareDialogComponent, FilterDialogComponent],
  imports: [
    OverlayModule,
    PortalModule,
    LegacyIconModule,
    DtTranslateModule,
    DtButtonModule,
    NzIconModule,
    ProfileAliasModule,
    InputModule,
    CommonModule,
    TextareaModule,
    NzSliderModule,
    FormsModule,
    CheckboxModule,
    RadioModule,
    SectionDescriptionModule,
    DatepickerRangeModule,
    NzSelectModule,
    EmptyContentModule,
    DtDialogModule,
    ReactiveFormsModule,
    MeasurementModule,
    DtLocationSelectModule,
    ConfirmDialogModule,
    DtFieldsModule,
    DtMediaContentModule
  ]
})
export class DialogsModule {}
