import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
@Directive({
  selector: '[appHandleLink]'
})
export class HandleLinkDirective implements OnInit {
  @Input() customEvent: boolean;

  @Output() linkClick = new EventEmitter<string>();

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (target.nodeName === 'A') {
      event.preventDefault();
      const link = target.getAttribute('href');
      if (!this.customEvent) {
        this.router.navigate([link]).then(() => {
          this.linkClick.emit();
        });
      } else {
        this.linkClick.emit(link);
      }
    }
  }

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
