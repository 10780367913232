import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DtProfileShort} from '@dt-lib/core/models/dt-profile.model';
import {DtPost} from '@dt-lib/modules/components/dt-post/models/dt-post.model';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {catchError, debounceTime, map, Observable, startWith, Subject, switchMap, takeUntil} from 'rxjs';
import {v4 as uuid} from 'uuid';

import {environment} from '../../../../../../environments/environment';
import {ChatService} from '../../../../../features/main/pages/chat/services/chat.service';
import {Profile, ProfileShort} from '../../../../../features/main/pages/profile/models/profile.model';
import {ProfileService} from '../../../../../features/main/pages/profile/services/profile.service';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit, OnDestroy {
  @Input() post: DtPost;
  @Input() profile: Profile;

  link: string;
  linkIsCopied = false;

  searchControl = new FormControl('');

  displayProfiles: DtProfileShort[] = [];
  displayProfilesLoaded = false;
  selectedProfileId: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: NzModalRef,
    private chatService: ChatService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.buildLink();
    this.initSearch();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private buildLink() {
    if (this.post) {
      this.link = `${environment.APP_URL}/profile/${this.post?.profile?.username}?post=${this?.post?.postUUID}`;
      return;
    }

    if (this.profile) {
      this.link = `${environment.APP_URL}/profile/${this.profile?.profileSummary?.username}`;
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.link).then(() => {
      this.linkIsCopied = true;
    });
  }

  private initSearch() {
    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        switchMap((value) => {
          this.displayProfiles = [];
          this.displayProfilesLoaded = false;
          if (value) {
            return this.profileService.searchShareProfiles(value).pipe(
              catchError(() => {
                return [];
              })
            );
          } else {
            return this.loadActiveConversations();
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((profiles) => {
        this.displayProfiles = profiles.filter((item: ProfileShort[]) => !!item);
        this.displayProfilesLoaded = true;
      });
  }

  private loadActiveConversations(): Observable<ProfileShort[]> {
    return this.chatService.getMyConversations(1, 20, 'ALL').pipe(
      map((conversations) => {
        return conversations.data.map((conversation) => conversation.receiver);
      })
    );
  }

  toggleCheck(profile: DtProfileShort): void {
    if (profile.userId) {
      this.selectedProfileId = profile.profileId;
    }
  }

  sendMessage(): void {
    this.chatService.getConversationWithProfile(this.selectedProfileId).subscribe(({channelId}) => {
      channelId = channelId || uuid();
      this.chatService.sendMessage(channelId, this.selectedProfileId, this.link).subscribe({
        next: () => {
          this.closeDialog(true);
        }
      });
    });
  }

  closeDialog(updated: boolean) {
    this.dialogRef.close(updated);
  }
}
