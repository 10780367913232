import {Injectable} from '@angular/core';
import {SafeHtml} from '@angular/platform-browser';
import {DtScriptsService} from '@dt-lib/core/services/dt-scripts.service';
import {TranslateService as NgxTranslateService} from '@ngx-translate/core';
import {en_US, NzI18nService, ro_RO} from 'ng-zorro-antd/i18n';
import {concat, Observable} from 'rxjs';

import {DtMessage, DtMessageConfig} from '../models/translate.model';

@Injectable({
  providedIn: 'root'
})
export class DtTranslateService {
  private supportedLanguages: string[] = ['en', 'ro'];
  predefinedLanguage: string;

  private _debugModeEnabled = false;

  get debugModeEnabled(): boolean {
    return this._debugModeEnabled;
  }

  constructor(
    private ngxTranslateService: NgxTranslateService,
    private nzI18nService: NzI18nService,
    private dtScriptsService: DtScriptsService
  ) {}

  getTranslations(lang: string): Observable<unknown> {
    return this.ngxTranslateService.getTranslation(lang);
  }

  setTranslations(lang: string): void {
    if (!this.supportedLanguages.includes(lang)) {
      lang = 'en';
    }
    this.ngxTranslateService.use(lang);
    switch (lang) {
      case 'en':
        this.nzI18nService.setLocale(en_US);
        break;
      case 'ro':
        this.nzI18nService.setLocale(ro_RO);
        break;
      default:
        this.nzI18nService.setLocale(en_US);
        break;
    }
  }

  getCurrentLanguage(): string {
    return this.ngxTranslateService.currentLang;
  }

  instant(key: DtMessage, params?: {[key: string]: string | SafeHtml}): string | any {
    if (this.debugModeEnabled) {
      return key;
    }
    const message: any = this.resolveMessage(key, (key as DtMessageConfig).translateParams || params);
    return this.ngxTranslateService.instant(message.translateKey, message.translateParams);
  }

  resolveMessage(message: DtMessage, params?: {[key: string]: string | SafeHtml}, untranslated?: string): DtMessage {
    if (untranslated || (message as DtMessageConfig)?.untranslated) {
      return {
        translateKey: '_UNTRANSLATED_MESSAGE',
        translateParams: {message: (message as DtMessageConfig)?.untranslated || untranslated}
      };
    }
    if (!message && message !== '') {
      return {
        translateKey: '_UNTRANSLATED_MESSAGE',
        translateParams: {message: ''}
      };
    }
    if (typeof message === 'string') {
      return {
        translateKey: message,
        translateParams: params
      };
    }
    if (message.translateKey) {
      return {
        translateKey: message.translateKey,
        translateParams: params
      };
    }
    return {
      translateKey: '_UNTRANSLATED_MESSAGE',
      translateParams: {message: 'INCORRECT TRANSLATION FORMAT'}
    };
  }

  setDebugMode(value: boolean): void {
    this._debugModeEnabled = value;
  }

  setEditMode(value: boolean): void {
    const crowdinParamsScriptId = 'crowdin-params-script';
    const crowdinParamsScript = document.getElementById(crowdinParamsScriptId);
    const crowdinScriptId = 'crowdin-script';
    const crowdinScript = document.getElementById(crowdinScriptId);
    if (value) {
      const crowdinParamsRequest = this.dtScriptsService.loadScript({
        id: crowdinParamsScriptId,
        content: `let _jipt = [['project', 'hunsy']];`
      });
      const crowdinRequest = this.dtScriptsService.loadScript({
        id: crowdinScriptId,
        src: 'https://cdn.crowdin.com/jipt/jipt.js'
      });
      if (!crowdinParamsScript && !crowdinScript) {
        concat(crowdinParamsRequest, crowdinRequest).subscribe(() => {
          this.setTranslations('pt');
        });
      }
    } else {
      if (crowdinParamsScript || crowdinScript) {
        window.location.reload();
      }
    }
  }
}
