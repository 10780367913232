import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';

import {RadioComponent} from './radio.component';

@NgModule({
  declarations: [RadioComponent],
  exports: [RadioComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DtTranslateModule]
})
export class RadioModule {}
