import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DtSequentialGuard} from '@dt-lib/core/guards/dt-sequential.guard';
import {DtLabelLoaderGuard} from '@dt-lib/modules/directives/dt-labels/guards/dt-label-loader.guard';
import {DtTranslationsResolver} from '@dt-lib/modules/pipes/dt-translate/resolvers/dt-translations.resolver';

import {environment} from '../environments/environment';
import {DynamicEnvironmentGuard} from './core/guards/dynamic-environment.guard';
import {UserLoaderGuard} from './core/guards/user-loader.guard';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [DtSequentialGuard],
    resolve: [DtTranslationsResolver],
    data: {environment, seqGuards: [DynamicEnvironmentGuard, DtLabelLoaderGuard]},
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'frontend/livez',
    loadChildren: () => import('./features/health/health.module').then((m) => m.HealthModule)
  },
  {
    path: '',
    canActivate: [DtSequentialGuard],
    resolve: [DtTranslationsResolver],
    data: {environment, seqGuards: [DynamicEnvironmentGuard, DtLabelLoaderGuard, UserLoaderGuard]},
    loadChildren: () => import('./features/main/main.module').then((m) => m.MainModule)
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
