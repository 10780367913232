import {Pipe, PipeTransform} from '@angular/core';
import {DtMedia} from '@dt-lib/core/models/dt-media-content.model';

@Pipe({
  name: 'dtDefaultAvatar'
})
export class DtDefaultAvatarPipe implements PipeTransform {
  transform(value: any): DtMedia {
    const defaultAvatarPath = 'assets/images/avatars/default-avatar.png';
    const defaultAvatar: DtMedia = {type: 'image', url: defaultAvatarPath};
    return value ? value : defaultAvatar;
  }
}
