<div class="dt-dialog-container-wrapper">
  <div class="dt-dialog-container">
    <div *ngIf="title" class="dt-dialog-header">
      <div class="dt-dialog-header__title">
        <ng-content select="dt-dialog-title"></ng-content>
      </div>
      <div *ngIf="closable" class="dt-dialog-header__close">
        <i nz-icon nzType="ng-zorro:close" (click)="handleCloseDialog()"></i>
      </div>
    </div>
    <div class="dt-dialog-body">
      <div
        #bodyContent
        class="dt-dialog-body__content"
        [class.full-width]="body?.fullWidth"
        [class.no-header]="!title"
        [class.no-padding]="!title || body?.removePadding">
        <ng-content select="dt-dialog-body"></ng-content>
      </div>
    </div>
    <div *ngIf="footer" class="dt-dialog-footer">
      <ng-content select="dt-dialog-footer"></ng-content>
    </div>
  </div>
</div>
