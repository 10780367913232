import {Component, Input, OnInit} from '@angular/core';

import {BaseFieldComponent} from '../base-field/base-field.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends BaseFieldComponent implements OnInit {
  @Input() height = '50px';
  @Input() resize = 'none';
  @Input() maxLength: number | string = 'unset';

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
