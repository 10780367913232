import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzMentionModule} from 'ng-zorro-antd/mention';

import {TextareaComponent} from './textarea.component';

@NgModule({
  declarations: [TextareaComponent],
  exports: [TextareaComponent],
  imports: [CommonModule, DtTranslateModule, ReactiveFormsModule, NzInputModule, NzMentionModule]
})
export class TextareaModule {}
