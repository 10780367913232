<dt-dialog>
  <dt-dialog-title class="header">
    <div class="header__title">
      {{ 'FILTERS' | dtTranslate }}
    </div>
    <div class="header__reset" (click)="resetFilters()">
      <i nz-icon nzType="ng-zorro:reset"></i>
      {{ 'RESET' | dtTranslate }}
    </div>
  </dt-dialog-title>
  <dt-dialog-body [fullWidth]="true">
    <dt-location-select
      *ngIf="filterConfig?.location"
      [locations]="currentLocations"
      [selectedLocationName]="currentLocation?.name"
      (changeLocation)="currentLocation = $event">
    </dt-location-select>
    <ng-container *ngIf="filterConfig?.radius">
      <div class="field-label">
        {{ 'FEED.SEARCH_RADIUS' | dtTranslate }}
      </div>
      <div class="radius-filter">
        <nz-slider nzMin="20" nzMax="300" nzTooltipVisible="never" [nzStep]="5" [(ngModel)]="currentRadius"></nz-slider>
        <div>
          <ng-container *ngIf="isMaxRadius"> {{ 'GLOBAL' | dtTranslate }} </ng-container>
          <ng-container *ngIf="!isMaxRadius">
            {{ currentRadius | measurement: 'distance' | async }}
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="filterConfig?.dates">
      <div class="field-label">
        {{ 'DATES_FIELD' | dtTranslate }}
      </div>
      <app-datepicker-range
        [startValue]="startDate"
        [endValue]="endDate"
        (startValueOnChange)="startDate = $event"
        (endValueOnChange)="endDate = $event">
      </app-datepicker-range>
    </ng-container>
    <ng-container *ngIf="filterConfig?.age">
      <div class="field-label">
        {{ 'AGE' | dtTranslate }}
      </div>
      <div class="input-range">
        <div class="input-range__from">
          <span>{{ 'PROFILE.EDIT_DETAILS.FROM' | dtTranslate }}</span>
          <span>{{ ageRange[0] }}</span>
        </div>
        <div class="input-range__to">
          <span>{{ 'PROFILE.EDIT_DETAILS.TO' | dtTranslate }}</span>
          <span>{{ ageTo }}</span>
        </div>
      </div>
      <nz-slider nzRange nzMin="18" nzMax="75" nzTooltipVisible="never" [(ngModel)]="ageRange"></nz-slider>
    </ng-container>
    <ng-container *ngIf="filterConfig?.keywords">
      <div class="field-label">
        {{ 'KEYWORDS' | dtTranslate }}
      </div>
      <app-input [placeholder]="'KEYWORD_PLACEHOLDER' | dtTranslate" [control]="keywordsControl"></app-input>
    </ng-container>
    <ng-container *ngIf="filterConfig?.ethnicity">
      <div class="field-label">
        {{ 'ETHNICITY' | dtTranslate }}
      </div>
      <nz-select [formControl]="ethnicityFieldsControl">
        <nz-option
          *ngFor="let ethnicity of ethnicityFields"
          [nzValue]="ethnicity.value"
          nzLabel="{{ ethnicity.label | dtTranslate }}">
        </nz-option>
      </nz-select>
    </ng-container>
  </dt-dialog-body>
  <dt-dialog-footer>
    <dt-button size="full" [disabled]="filterConfig?.location && !currentLocation" (click)="closeDialog(true)">
      {{ 'APPLY_SETTINGS' | dtTranslate }}
    </dt-button>
  </dt-dialog-footer>
</dt-dialog>
