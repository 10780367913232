<dt-dialog>
  <dt-dialog-title>
    {{ 'SHARE' | dtTranslate }}
  </dt-dialog-title>
  <dt-dialog-body class="content" [fullWidth]="true">
    <div class="share-actions">
      <div class="share-actions__item" (click)="copyLink()">
        <div class="share-actions__item-logo">
          <i nz-icon [nzType]="'ng-zorro:link'" [style.color]="'#707B86'"></i>
        </div>
        <div class="share-actions__item-title">
          {{ (linkIsCopied ? 'COPIED' : 'COPY_LINK') | dtTranslate }}
        </div>
      </div>
    </div>
    <dt-input align="center" [prefixRef]="searchIcon" [placeholder]="'SEARCH'" [control]="searchControl"> </dt-input>
    <ng-template #searchIcon>
      <i nz-icon nzType="ng-zorro:search" class="search-icon"></i>
    </ng-template>
    <div class="profiles-container">
      <app-profile-alias
        *ngFor="let profile of displayProfiles"
        [class.disabled]="!profile.userId"
        [profile]="profile"
        [redirectable]="false"
        (click)="toggleCheck(profile)">
        <div class="profile-alias-actions">
          <div class="checkbox" [class.active]="profile.profileId === selectedProfileId">
            <i nz-icon nzType="ng-zorro:check"></i>
          </div>
        </div>
      </app-profile-alias>
      <ng-container *ngIf="!displayProfilesLoaded">
        <app-profile-alias [onlyTitle]="true"></app-profile-alias>
        <app-profile-alias [onlyTitle]="true"></app-profile-alias>
        <app-profile-alias [onlyTitle]="true"></app-profile-alias>
        <app-profile-alias [onlyTitle]="true"></app-profile-alias>
        <app-profile-alias [onlyTitle]="true"></app-profile-alias>
      </ng-container>
      <app-empty-content
        *ngIf="displayProfilesLoaded && !displayProfiles.length"
        [height]="'300px'"
        [borderLess]="true"
        [nzIconType]="'ng-zorro:users'"
        [title]="'CHAT.NO_ACTIVE_CHATS' | dtTranslate"
        [description]="'CHAT.NO_ACTIVE_CHATS_DESC' | dtTranslate">
      </app-empty-content>
    </div>
    <div *ngIf="selectedProfileId" class="message">
      <dt-button size="full" (click)="sendMessage()"> {{ 'SHARE' | dtTranslate }} </dt-button>
    </div>
  </dt-dialog-body>
</dt-dialog>
