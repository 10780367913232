import {NgModule} from '@angular/core';
import {DtButtonModule} from '@dt-lib/modules/components/dt-button/dt-button.module';
import {DtDialogModule} from '@dt-lib/modules/components/dt-dialogs/dt-dialog.module';
import {DtMediaContentModule} from '@dt-lib/modules/components/dt-media-content/dt-media-content.module';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {CheckboxModule} from '../../../fields/components/checkbox/checkbox.module';
import {SectionDescriptionModule} from '../../../section-description/section-description.module';
import {ConfirmDialogComponent} from './confirm-dialog.component';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [
    DtDialogModule,
    DtTranslateModule,
    NzIconModule,
    DtMediaContentModule,
    SectionDescriptionModule,
    CheckboxModule,
    DtButtonModule
  ],
  exports: [ConfirmDialogComponent]
})
export class ConfirmDialogModule {}
