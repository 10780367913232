import {Injectable} from '@angular/core';
import * as icons from '@dt-lib/core/constants/dt-custom-icons.constants';
import * as invisibleIcons from '@dt-lib/core/constants/dt-invisible-icons.constants';
import * as reactionsIcons from '@dt-lib/core/constants/dt-reactions-icons.constants';
import {NzIconService} from 'ng-zorro-antd/icon';

@Injectable({
  providedIn: 'root'
})
export class DtCustomIconsService {
  iconsMap: Map<string, string> = new Map([
    ['ng-zorro:arrow-up', icons.ArrowUp],
    ['ng-zorro:arrow-down', icons.ArrowDown],
    ['ng-zorro:arrow-left', icons.ArrowLeft],
    ['ng-zorro:arrow-right', icons.ArrowRight],
    ['ng-zorro:sidebar-home', icons.SidebarHome],
    ['ng-zorro:dates', icons.Dates],
    ['ng-zorro:shop', icons.Shop],
    ['ng-zorro:close', icons.Close],
    ['ng-zorro:lock', icons.Lock],
    ['ng-zorro:filled-lock', icons.FilledLock],
    ['ng-zorro:public', icons.Public],
    ['ng-zorro:broken-heart', icons.BrokenHeart],
    ['ng-zorro:horizontal-rectangle', icons.HorizontalRectangle],
    ['ng-zorro:vertical-rectangle', icons.VerticalRectangle],
    ['ng-zorro:square', icons.Square],
    ['ng-zorro:voice', icons.Voice],
    ['ng-zorro:muted', icons.Muted],
    ['ng-zorro:blocks', icons.Blocks],
    ['ng-zorro:muted', icons.Muted],
    ['ng-zorro:square-logout', icons.SquareLogout],
    ['ng-zorro:dollar', icons.Dollar],
    ['ng-zorro:euro', icons.Euro],
    ['ng-zorro:currency', icons.Currency],
    ['ng-zorro:currency-full', icons.CurrencyFull],
    ['ng-zorro:crown', icons.Crown],
    ['ng-zorro:fingers', icons.Fingers],
    ['ng-zorro:verified', icons.Verified],
    ['ng-zorro:circle', icons.Circle],
    ['ng-zorro:glasses', icons.Glasses],
    ['ng-zorro:fingers-outline', icons.FingersOutline],
    ['ng-zorro:notes', icons.Notes],
    ['ng-zorro:paid-content', icons.PaidContent],
    ['ng-zorro:invisible', icons.Invisible],
    ['ng-zorro:location', icons.Location],
    ['ng-zorro:fire', icons.Fire],
    ['ng-zorro:heart', icons.Heart],
    ['ng-zorro:heart-outline', icons.HeartOutine],
    ['ng-zorro:cup', icons.Cup],
    ['ng-zorro:boost', icons.Boost],
    ['ng-zorro:star', icons.Star],
    ['ng-zorro:education', icons.Education],
    ['ng-zorro:facebook', icons.Facebook],
    ['ng-zorro:messenger', icons.Messenger],
    ['ng-zorro:link', icons.Link],
    ['ng-zorro:send', icons.Send],
    ['ng-zorro:filters', icons.Filters],
    ['ng-zorro:royal-tv', icons.RoyalTv],
    ['ng-zorro:planet', icons.Planet],
    ['ng-zorro:size', icons.Size],
    ['ng-zorro:athletic', icons.Athletic],
    ['ng-zorro:treading-up', icons.TreadingUp],
    ['ng-zorro:info-hexagon', icons.InfoHexagon],
    ['ng-zorro:calendar', icons.Calendar],
    ['ng-zorro:light-bulb', icons.LightBulb],
    ['ng-zorro:bucket', icons.Bucket],
    ['ng-zorro:us-flag', icons.UsFlag],
    ['ng-zorro:ua-flag', icons.UaFlag],
    ['ng-zorro:kz-flag', icons.KzFlag],
    ['ng-zorro:copy', icons.Copy],
    ['ng-zorro:reset', icons.Reset],
    ['ng-zorro:ethnicity', icons.Ethnicity],
    ['ng-zorro:age-limit', icons.AgeLimit],
    ['ng-zorro:children-horse', icons.ChildrenHorse],
    ['ng-zorro:statistics', icons.Statistics],
    ['ng-zorro:cigarette', icons.Cigarette],
    ['ng-zorro:empty-glasses', icons.EmptyGlasses],
    ['ng-zorro:empty-heart', icons.EmptyHeart],
    ['ng-zorro:copy', icons.Copy],
    ['ng-zorro:bucket', icons.Bucket],
    ['ng-zorro:navigation', icons.Navigation],
    ['ng-zorro:archive', icons.Archive],
    ['ng-zorro:credit-card', icons.CreditCard],
    ['ng-zorro:users', icons.Users],
    ['ng-zorro:chat', icons.Chat],
    ['ng-zorro:chat-new', icons.ChatNew],
    ['ng-zorro:chat-unread', icons.ChatUnread],
    ['ng-zorro:more', icons.More],
    ['ng-zorro:video', icons.Video],
    ['ng-zorro:video-filled', icons.VideoFilled],
    ['ng-zorro:muted-video', icons.MutedVideo],
    ['ng-zorro:double-hearts', icons.DoubleHearts],
    ['ng-zorro:user-outlined', icons.UserOutlined],
    ['ng-zorro:reply', icons.Reply],
    ['ng-zorro:report', icons.Report],
    ['ng-zorro:dice', icons.Dice],
    ['ng-zorro:translate', icons.Translate],
    ['ng-zorro:check', icons.Check],
    ['ng-zorro:double-check', icons.DoubleCheck],
    ['ng-zorro:menu', icons.Menu],
    ['ng-zorro:cross', icons.Cross],
    ['ng-zorro:stop-circle', icons.StopCircle],
    ['ng-zorro:back-arrow', icons.BackArrow],
    ['ng-zorro:search', icons.Search],
    ['ng-zorro:picture', icons.Picture],
    ['ng-zorro:post-reply', icons.PostReply],
    ['ng-zorro:info', icons.Info],
    ['ng-zorro:configuration', icons.Configuration],
    ['ng-zorro:post-reply', icons.PostReply],
    ['ng-zorro:exit', icons.Exit],
    ['ng-zorro:configuration', icons.Configuration],
    ['ng-zorro:micro', icons.Micro],
    ['ng-zorro:muted-micro', icons.MutedMicro],
    ['ng-zorro:handset', icons.Handset],
    ['ng-zorro:lowered-handset', icons.LoweredHandset],
    ['ng-zorro:swap', icons.Swap],
    ['ng-zorro:star-reaction', reactionsIcons.StarReaction],
    ['ng-zorro:gift-reaction', reactionsIcons.GiftReaction],
    ['ng-zorro:baby-crown-reaction', reactionsIcons.BabyCrownReaction],
    ['ng-zorro:diamond-reaction', reactionsIcons.DiamondReaction],
    ['ng-zorro:kiss-reaction', reactionsIcons.KissReaction],
    ['ng-zorro:confetti-reaction', reactionsIcons.ConfettiReaction],
    ['ng-zorro:daddy-crown-reaction', reactionsIcons.DaddyCrownReaction],
    ['ng-zorro:heart-reaction', reactionsIcons.HeartReaction],
    ['ng-zorro:fullscreen-enable', icons.FullscreenEnable],
    ['ng-zorro:fullscreen-disable', icons.FullscreenDisable],
    ['ng-zorro:minimize', icons.Minimize],
    ['ng-zorro:success-arrow', icons.SuccessArrow],
    ['ng-zorro:fail-arrow', icons.FailArrow],
    ['ng-zorro:help-circle', icons.HelpCircle],
    ['ng-zorro:pause', icons.Pause],
    ['ng-zorro:lion', invisibleIcons.Lion],
    ['ng-zorro:wolf', invisibleIcons.Wolf],
    ['ng-zorro:dog', invisibleIcons.Dog],
    ['ng-zorro:cat', invisibleIcons.Cat],
    ['ng-zorro:fox', invisibleIcons.Fox],
    ['ng-zorro:panther', invisibleIcons.Panther],
    ['ng-zorro:profile', icons.Profile],
    ['ng-zorro:edit', icons.Edit],
    ['ng-zorro:renew', icons.Renew],
    ['ng-zorro:plus', icons.Plus],
    ['ng-zorro:unlock', icons.Unlock],
    ['ng-zorro:clock', icons.Clock],
    ['ng-zorro:plus-circle', icons.PlusCircle],
    ['ng-zorro:settings', icons.Settings],
    ['ng-zorro:eye', icons.Eye],
    ['ng-zorro:eye-crossed', icons.EyeCrossed],
    ['ng-zorro:daddy-icon', icons.DaddyIcon],
    ['ng-zorro:pentagon-cross', icons.PentagonCross],
    ['ng-zorro:pin', icons.Pin],
    ['ng-zorro:shield-cross', icons.ShieldCross],
    ['ng-zorro:triple-heart', icons.TripleHeart],
    ['ng-zorro:mastercard', icons.Mastercard],
    ['ng-zorro:visa', icons.Visa],
    ['ng-zorro:upload', icons.Upload],
    ['ng-zorro:download', icons.Download],
    ['ng-zorro:shield-cross', icons.ShieldCross],
    ['ng-zorro:map-pin', icons.MapPin],
    ['ng-zorro:play', icons.Play],
    ['ng-zorro:stop', icons.Stop],
    ['ng-zorro:zoom-in', icons.ZoomIn],
    ['ng-zorro:colors', icons.Colors],
    ['ng-zorro:finger-up', icons.FingerUp],
    ['ng-zorro:credit-card', icons.CreditCard],
    ['ng-zorro:location-arrow', icons.LocationArrow],
    ['ng-zorro:referral-icon', icons.ReferralIcon],
    ['ng-zorro:switch-time', icons.SwitchTime],
    ['ng-zorro:thumb-up', icons.ThumbUp],
    ['ng-zorro:popularity-low', icons.PopularityLow],
    ['ng-zorro:popularity-medium', icons.PopularityMedium],
    ['ng-zorro:popularity-high', icons.PopularityHigh],
    ['ng-zorro:file', icons.File],
    ['ng-zorro:time', icons.Time],
    ['ng-zorro:empty-star', icons.EmptyStar],
    ['ng-zorro:menu-list', icons.MenuList],
    ['ng-zorro:mastercard-logo', icons.MastercardLogo],
    ['ng-zorro:maestro-logo', icons.MaestroLogo],
    ['ng-zorro:visa-logo', icons.VisaLogo],
    ['ng-zorro:budget', icons.Budget],
    ['ng-zorro:tag', icons.Tag],
    ['ng-zorro:calendar-heart', icons.CalendarHeart],
    ['ng-zorro:chat-arrow', icons.ChatArrow],
    ['ng-zorro:square-edit', icons.SquareEdit],
    ['ng-zorro:bell', icons.Bell],
    ['ng-zorro:privacy', icons.Privacy],
    ['ng-zorro:empty-verified', icons.EmptyVerified],
    ['ng-zorro:mobile', icons.Mobile],
    ['ng-zorro:computer', icons.Computer],
    ['ng-zorro:muted-filled', icons.MutedFilled],
    ['ng-zorro:pinned-filled', icons.PinnedFilled],
    ['ng-zorro:pin-crossed', icons.PinCrossed],
    ['ng-zorro:gif', icons.Gif],
    ['ng-zorro:google', icons.Google],
    ['ng-zorro:event', icons.Event],
    ['ng-zorro:white-label', icons.WhiteLabel]
  ]);

  constructor(private nzIconService: NzIconService) {}

  registerCustomIcons() {
    this.iconsMap.forEach((value, key) => {
      this.nzIconService.addIconLiteral(key, value);
    });
  }
}
