import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DtLabelConfig, DtLabels} from '@dt-lib/modules/directives/dt-labels/models/dt-labels.model';
import {catchError, map, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtLabelsService {
  private labelConfig: DtLabelConfig;

  private defaultLabelConfig: DtLabelConfig = {
    origin: 'suggie.com',
    domain: 'https://suggie.com',
    language: 'en',
    location: {
      name: 'London, England, United Kingdom',
      coordinates: [-0.118, 51.5098]
    }
  };

  get origin(): string {
    return this.labelConfig?.origin;
  }

  get domain(): string {
    return this.labelConfig?.domain;
  }

  get language(): string {
    return this.labelConfig?.language;
  }

  get isMainLabel(): boolean {
    return this.origin === DtLabels.MAIN;
  }

  get isRomanianLabel(): boolean {
    return this.origin === DtLabels.ROMANIAN;
  }

  constructor(private http: HttpClient) {}

  initLabel(isProduction: boolean, domain: string, apiUrl: string): Observable<boolean> {
    if (!this.labelConfig) {
      if (!isProduction) {
        domain = localStorage.getItem('labelDomain') || domain;
      }
      return this.http
        .get<DtLabelConfig>(`${apiUrl}/profiles/admin/settings/origin/by-domain`, {
          params: {domain, ignoreErrorWarning: true, ignoreSessionRedirect: true}
        })
        .pipe(
          catchError(() => {
            return of(this.defaultLabelConfig);
          }),
          map((labelConfig: DtLabelConfig) => {
            this.labelConfig = labelConfig || this.defaultLabelConfig;
            return true;
          })
        );
    } else {
      return of(true);
    }
  }
}
