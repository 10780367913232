import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dt-dialog-body',
  template: '<ng-content></ng-content>'
})
export class DtDialogBodyComponent implements OnInit {
  @Input() fullWidth = false;
  @Input() removePadding = false;

  constructor() {}

  ngOnInit(): void {}
}
