import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl} from '@angular/forms';

import {RadioFieldConfig} from './models/radio.model';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() fields: RadioFieldConfig[];
  @Input() control: AbstractControl;
  @Input() name: string;
  @Input() label: string;
  @Input() direction: 'row' | 'column' = 'row';

  @Output() valueChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onChange(value: any) {
    this.control?.patchValue(value);
    this.valueChange.emit(value);
  }
}
