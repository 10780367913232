import {Injectable, Injector, Type} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {concatMap, from, Observable, of, reduce, takeWhile} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtSequentialGuard {
  constructor(private injector: Injector) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const guards = (route.data['seqGuards'] as Type<CanActivate>[]) || [];

    if (guards.length === 0) {
      return of(true);
    }

    return from(guards).pipe(
      concatMap((guard): Observable<boolean> => {
        const guardInstance = this.injector.get(guard);
        return guardInstance.canActivate(route, state) as Observable<boolean>;
      }),
      takeWhile((result) => result === true, true),
      reduce((acc, result) => acc && result, true)
    );
  }
}
