export enum DtLabels {
  MAIN = 'suggie.com',
  ROMANIAN = 'suggie.ro'
}

export interface DtLabelConfig {
  origin: string;
  domain: string;
  language: string;
  location: {
    name: string;
    coordinates: number[];
  };
}
