import {CommonModule} from '@angular/common';
import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DtButtonModule} from '@dt-lib/modules/components/dt-button/dt-button.module';
import {DtDialogModule} from '@dt-lib/modules/components/dt-dialogs/dt-dialog.module';
import {DtFieldsModule} from '@dt-lib/modules/components/dt-fields/dt-fields.module';
import {DtMapDialogComponent} from '@dt-lib/modules/components/dt-location-select/components/dt-map-dialog/dt-map-dialog.component';
import {DtLocationSelectComponent} from '@dt-lib/modules/components/dt-location-select/dt-location-select.component';
import {DtTranslateModule} from '@dt-lib/modules/pipes/dt-translate/dt-translate.module';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';

export const MAP_API_KEY = new InjectionToken<string>('MAP_API_KEY');

@NgModule({
  declarations: [DtLocationSelectComponent, DtMapDialogComponent],
  exports: [DtLocationSelectComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzDividerModule,
    NzIconModule,
    DtDialogModule,
    DtButtonModule,
    DtFieldsModule,
    DtTranslateModule
  ]
})
export class DtLocationSelectModule {
  static forRoot(mapApiKey: string): ModuleWithProviders<DtLocationSelectModule> {
    return {
      ngModule: DtLocationSelectModule,
      providers: [{provide: MAP_API_KEY, useValue: mapApiKey}]
    };
  }
}
