import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {DtDialogBodyComponent} from '@dt-lib/modules/components/dt-dialogs/components/dt-dialog/dt-dialog-body/dt-dialog-body.component';
import {DtDialogFooterComponent} from '@dt-lib/modules/components/dt-dialogs/components/dt-dialog/dt-dialog-footer/dt-dialog-footer.component';
import {NzModalRef} from 'ng-zorro-antd/modal';

import {DtDialogTitleComponent} from './dt-dialog-title/dt-dialog-title.component';

@Component({
  selector: 'dt-dialog',
  templateUrl: './dt-dialog.component.html',
  styleUrls: ['./dt-dialog.component.scss']
})
export class DtDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() closable = true;
  @Input() animateDynamicContent = false;

  @Output() closeDialog = new EventEmitter<boolean>();

  @ContentChild(DtDialogTitleComponent) title: DtDialogTitleComponent;
  @ContentChild(DtDialogBodyComponent) body: DtDialogBodyComponent;
  @ContentChild(DtDialogFooterComponent) footer: DtDialogFooterComponent;

  @ViewChild('bodyContent') bodyContent: ElementRef<HTMLElement>;

  private observer: MutationObserver;

  constructor(private dialogRef: NzModalRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.animateDynamicContent) {
      this.handleDynamicContent();
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  handleCloseDialog(updated = false) {
    this.dialogRef.close(updated);
    this.closeDialog.emit(updated);
  }

  private handleDynamicContent(): void {
    const element = this.bodyContent.nativeElement;
    const content = element.firstElementChild as HTMLElement;
    let elementHeight = element.offsetHeight;
    let contentHeight = content.offsetHeight;
    element.style.height = elementHeight + 'px';
    element.style.transition = 'height 0.3s ease-out';
    this.observer = new MutationObserver(() => {
      const newContentHeight = content.offsetHeight;
      const newElementHeight = elementHeight + (newContentHeight - contentHeight);
      elementHeight = newElementHeight;
      contentHeight = newContentHeight;
      requestAnimationFrame(() => {
        element.style.height = newElementHeight + 'px';
      });
    });
    this.observer.observe(this.bodyContent.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true
    });
  }
}
