import {ProfileShort} from '../../profile/models/profile.model';

export interface GeneralInfo {
  email?: string;
  isEmailVerified?: boolean;
  phoneNumber?: string;
  isPhoneVerified?: boolean;
  language?: string;
  translationLanguage?: string;
  baseCurrency?: string;
  measurement?: Measurements;
  timezone?: {
    time: string;
    code: string;
  };
  private?: {
    showOnline: boolean;
  };
  isAutoRenewSubscription?: boolean;
  fanRequestNeeded?: boolean;
  translateAllConversations?: boolean;
}

export enum Measurements {
  METRIC = 'metric',
  IMPERIAL = 'imperial'
}

export interface Language {
  locale: string;
  name: string;
  nativeName: string;
  isDefault?: boolean;
  flagIcon?: string;
}

export interface Currency {
  id: string;
  name: string;
  symbol: string;
}

export interface UserExportData {
  userData: object;
  profileData: object;
  PSInfo: object;
  verificationData: object;
  gdprData: object[];
  bankTransactions: object[];
}

export interface NotificationsInfo {
  disturbEndAt: string;
  wsNotification?: Notifications;
  emailNotification?: Notifications;
}

export interface Notifications {
  enabled: boolean;
  followings: boolean;
  likes: boolean;
  news: boolean;
  requests: boolean;
  subscriptions: boolean;
  tokens: boolean;
  chat: boolean;
  privateGalery: boolean;
}

export interface VisibilitySettings {
  type: VisibilityTypes;
}

export enum VisibilityTypes {
  LION = 'lion',
  WOLF = 'wolf',
  DOG = 'dog',
  CAT = 'cat',
  FOX = 'fox',
  PANTHER = 'panther'
}

export interface PrivacySettings {
  profileVisibility: 'everyone' | 'registered';
  showOnline: boolean;
  showRating: boolean;
  videoCallEnabled: 'everyone' | 'nobody' | 'followers' | 'fans' | 'followersAndFans';
  allowedUsers: string[];
}

export enum TwoFactorTypes {
  EMAIL = 'email',
  PHONE = 'phone',
  DISABLED = 'disabled'
}

export interface SecuritySettings {
  twoFactor: TwoFactorTypes;
  lastPasswordChangedAt: string;
}

export interface FanPlanInfo {
  canHaveFreeTrial: boolean;
  hasPendingFanRequest: boolean;
  fanPlan: FanPlan;
}

export interface FanPlan {
  id?: string;
  fanPlanId?: string;
  cost?: number;
  category?: string;
  freeTrial?: '1h' | '1d' | '1w' | '1m';
  isActive?: boolean;
  isDefault?: boolean;
  isRequestForAccess?: boolean;
  hasPendingFanRequest?: boolean;
  createdAt?: string;
  updatedAt?: string;
  finishedAt?: string;
}

export interface Session {
  ip: string;
  osType: string;
  osVersion: string;
  browser: string;
  city: string;
  country: string;
  timezone: string;
  isp: string;
  createdAt: string;
  isMobile: boolean;
}

export interface Note {
  _id: string;
  note: string;
  notes: string[];
  relatedProfile: ProfileShort | {_id: string}[];
  createdAt: string;
  collapsed?: boolean;
}

export interface BlockedUsersResponse {
  data: {
    targetProfile: {
      _id: string;
    };
  }[];
}
