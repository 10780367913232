import {DtProfileType} from '@dt-lib/core/models/dt-profile.model';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {Currency} from '../../../features/main/pages/settings/models/settings.model';
import {User} from '../../models/user.model';
import {USER_FEATURE_KEY, UserState} from '../state/user.state';

const selectUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const selectUser = createSelector(selectUserState, (state: UserState) => {
  return state?.user;
});

export const selectUserProfileId = createSelector(selectUser, (user: User) => {
  return user?.profileId;
});

export const selectUserIsDaddy = createSelector(selectUser, (user: User) => {
  return user?.profileType === DtProfileType.DADDY;
});

export const selectUserTokenBalance = createSelector(selectUser, (user: User) => {
  return user?.balance?.token?.balance ?? 0;
});

export const selectUserTokenBufferBalance = createSelector(selectUser, (user: User) => {
  return user?.balance?.token?.bufferBalance ?? 0;
});

export const selectUserFiatBalance = createSelector(selectUser, (user: User) => {
  const currency = user.balance.baseCurrency as Currency;
  const balance = user?.balance?.fiat?.balance ?? 0;
  return `≈ ${currency.symbol} ${balance}`;
});

export const selectUserFiatEqual = (tokensAmount: number) =>
  createSelector(selectUser, (user: User) => {
    const currency = user.balance.baseCurrency as Currency;
    const balance = (tokensAmount * user.balance.rate * user.balance.tokenPriceFiat).toFixed(2);
    return `≈ ${currency.symbol} ${balance}`;
  });

export const selectUserMediaAgreement = createSelector(selectUser, (user: User) => {
  return user?.agreement?.mediaAgreements;
});

export const selectUserChatAgreement = createSelector(selectUser, (user: User) => {
  return user?.agreement?.chatMediaAgreements;
});

export const selectUserPremiumPurchaseByTokensBlockTime = createSelector(selectUser, (user: User) => {
  return user?.paymentsBlocks?.request_lock_ps_purchase_with_tokens;
});

export const selectUserPremiumPurchaseByCardBlockTime = createSelector(selectUser, (user: User) => {
  return user?.paymentsBlocks?.request_lock_ps_purchase_with_card;
});

export const selectUserTokensPurchaseBlockTime = createSelector(selectUser, (user: User) => {
  return user?.paymentsBlocks?.request_lock_token_purchase;
});

export const selectUserTokensWithdrawBlockTime = createSelector(selectUser, (user: User) => {
  return user?.paymentsBlocks?.request_lock_token_withdraw;
});

export const selectUserAttachCardBlockTime = createSelector(selectUser, (user: User) => {
  return user?.paymentsBlocks?.request_lock_attach_card;
});
