import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DtCustomIconsService} from '@dt-lib/core/services/dt-custom-icons.service';
import {DtScriptsService} from '@dt-lib/core/services/dt-scripts.service';
import {DtUserpilotService} from '@dt-lib/core/services/dt-userpilot.service';
import {DtUtmService} from '@dt-lib/core/services/dt-utm.service';
import {DtLabelsService} from '@dt-lib/modules/directives/dt-labels/services/dt-labels.service';
import {DtTranslatePipe} from '@dt-lib/modules/pipes/dt-translate/dt-translate.pipe';
import {DtTranslateService} from '@dt-lib/modules/pipes/dt-translate/services/dt-translate.service';
import mixpanel from 'mixpanel-browser';
import {catchError, filter, fromEvent, map, of, Subject, take, takeUntil} from 'rxjs';

import {environment} from '../environments/environment';
import {DevToolsService} from './core/services/dev-tools.service';
import {NotificationsService} from './core/services/notifications.service';
import {ScreenService} from './core/services/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private dtCustomIconsService: DtCustomIconsService,
    private dtTranslateService: DtTranslateService,
    private dtUserpilotService: DtUserpilotService,
    private dtScriptsService: DtScriptsService,
    private dtUtmService: DtUtmService,
    private dtLabelsService: DtLabelsService,
    private dtTranslatePipe: DtTranslatePipe,
    private devToolsService: DevToolsService,
    private screenService: ScreenService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.initIcons();
    this.initScreenService();
    this.initMixpanel();
    this.initUserpilot();
    this.loadCookieScript();
    this.loadGoogleTagManagerScript();
    this.loadClarityScript();
    this.handleUtmParams();
    this.handleDevToolsConfigUpdates();
    this.handleConnection();
    this.handleVisibilityChange();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initIcons(): void {
    this.dtCustomIconsService.registerCustomIcons();
  }

  private initScreenService(): void {
    this.screenService.init();
  }

  private initUserpilot(): void {
    this.dtUserpilotService.init(environment.USER_PILOT_KEY, environment.production);
    this.handleUserpilotReload();
  }

  private handleUserpilotReload(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.dtUserpilotService.reload();
      });
  }

  private initMixpanel(): void {
    mixpanel.init(environment.MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: false,
      persistence: 'localStorage'
    });
    (window as any).mixpanel = mixpanel;
  }

  private loadCookieScript(): void {
    const url = `//cdn.cookie-script.com/s/${environment.COOKIE_SCRIPT_ID}.js`;
    this.dtScriptsService.loadScript({id: 'cookie-script', src: url}).subscribe();
  }

  private loadGoogleTagManagerScript(): void {
    let id = 'GTM-M2HLVFTZ';
    if (this.dtLabelsService.isRomanianLabel) {
      id = 'GTM-MHLKPKCR';
    }
    this.dtScriptsService
      .loadScript({
        id: 'gtm-script',
        type: 'text/partytown',
        content: `(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${id}');`
      })
      .subscribe(() => {
        window.dispatchEvent(new CustomEvent('ptupdate'));
      });
  }

  private loadClarityScript(): void {
    if (environment.production) {
      let id = 'kdbulajeh3';
      if (this.dtLabelsService.isRomanianLabel) {
        id = 'pqt7pe26eu';
      }
      this.dtScriptsService
        .loadScript({
          id: 'clarity-script',
          type: 'text/javascript',
          content: `(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", '${id}');`
        })
        .subscribe();
    }
  }

  private loadDriftlyScript(): void {
    const script = document.createElement('script');
    script.setAttribute('driftly-api', '6jYDILFiWVMAiPWMRvwpm2QxykLm4QoCLa9OorkVHnITy');
    script.src = 'https://storage.googleapis.com/driftly-cdn/driftly-loader.umd.js';
    document.head.appendChild(script);
  }

  private handleUtmParams(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.dtUtmService.setUtmList(this.activatedRoute.snapshot.queryParams);
      });
  }

  private handleDevToolsConfigUpdates(): void {
    this.devToolsService.getDevToolsConfigChanges().subscribe((config) => {
      this.dtTranslateService.setDebugMode(config.translationsDisabled);
      this.dtTranslateService.setEditMode(config.translationsEditable);
    });
  }

  private handleConnection(): void {
    fromEvent(window, 'online')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.notificationsService.createInfoNotification(this.dtTranslatePipe.transform('ALERTS.CONNECTION_RESTORED'));
      });
    fromEvent(window, 'offline')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.notificationsService.createWarningNotification(this.dtTranslatePipe.transform('ALERTS.CONNECTION_LOST'));
      });
  }

  private handleVisibilityChange(): void {
    fromEvent(document, 'visibilitychange')
      .pipe(
        map(() => document.visibilityState === 'visible'),
        takeUntil(this.destroyed$)
      )
      .subscribe((isVisible) => {
        if (isVisible) {
          this.checkAppVersion();
        }
      });
  }

  private checkAppVersion(): void {
    const url = window.location.href;
    this.http
      .get(`${url}?time=${new Date().getTime()}`, {
        responseType: 'text',
        params: {
          ignoreErrorWarning: true,
          ignoreSessionRedirect: true
        }
      })
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((html) => {
        if (html) {
          const serverDocument = new DOMParser().parseFromString(html, 'text/html');
          const serverVersion = serverDocument.querySelector('meta[property="version"]')?.getAttribute('content');
          const currentVersion = document.querySelector('meta[property="version"]')?.getAttribute('content');
          if (serverVersion !== currentVersion) {
            this.notificationsService.createInfoNotification(
              this.dtTranslatePipe.transform('ALERTS.NEW_VERSION_DESC'),
              this.dtTranslatePipe.transform('ALERTS.NEW_VERSION')
            );
          }
        }
      });
  }
}
