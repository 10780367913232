import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DtProfilesService} from '@dt-lib/core/services/dt-profiles.service';
import {map, mergeMap, Observable, of, shareReplay, switchMap, tap} from 'rxjs';

import {environment} from '../../../../../../environments/environment';
import {DateUtil} from '../../../../../core/utils/date.util';
import {Profile, ProfileShort} from '../../profile/models/profile.model';
import {currencies} from '../constants/settings.constants';
import {
  BlockedUsersResponse,
  Currency,
  FanPlan,
  GeneralInfo,
  Language,
  Note,
  Notifications,
  NotificationsInfo,
  PrivacySettings,
  SecuritySettings,
  Session,
  UserExportData,
  VisibilitySettings,
  VisibilityTypes
} from '../models/settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private generalInfoRequest: Observable<GeneralInfo>;

  private generalInfo: GeneralInfo;
  private privacySettings: PrivacySettings;
  private securitySettings: SecuritySettings;

  private visibilityRequest: Observable<VisibilitySettings>;

  constructor(
    private http: HttpClient,
    private dtProfilesService: DtProfilesService
  ) {}

  getMyGeneralInfo(resetCache?: boolean): Observable<GeneralInfo> {
    if (!this.generalInfoRequest || resetCache) {
      this.generalInfoRequest = this.http.get<GeneralInfo>(`${environment.API_URL}/profiles/settings/my/general`).pipe(
        tap((generalInfo) => {
          this.generalInfo = generalInfo;
        }),
        shareReplay(1)
      );
    }
    return this.generalInfoRequest;
  }

  updateMyGeneralInfo(params: GeneralInfo): Observable<GeneralInfo> {
    params = {
      language: this.generalInfo.language,
      baseCurrency: this.generalInfo.baseCurrency,
      measurement: this.generalInfo.measurement,
      timezone: DateUtil.getTimezoneInfo(),
      fanRequestNeeded: true,
      isAutoRenewSubscription: true,
      ...params
    };
    return this.http.put<GeneralInfo>(`${environment.API_URL}/profiles/settings/my/general`, params).pipe(
      switchMap(() => {
        return this.getMyGeneralInfo(true);
      })
    );
  }

  sendConfirmationLink(): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/user/my/email/confirm`, {});
  }

  changeEmail(email: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/user/my/email`, {email});
  }

  verifyEmail(code: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.API_URL}/profiles/user/my/email/verify`, {code});
  }

  changePhone(phoneNumber: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/user/my/phone/set`, {phoneNumber});
  }

  verifyPhone(code: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/user/my/phone/verify`, {code});
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${environment.API_URL}/profiles/settings/list/languages`);
  }

  getCurrencies(): Observable<Currency[]> {
    return this.http
      .get<{[currencyId: string]: number}[]>(`${environment.API_URL}/profiles/settings/list/currencies`)
      .pipe(
        map((currenciesInfo) => {
          return Object.keys(currenciesInfo).map((currencyId) => {
            return this.getCurrencyById(currencyId);
          });
        })
      );
  }

  getCurrencyById(currencyId: string): Currency {
    return currencies.find((currency) => currency.id === currencyId);
  }

  requestData(password: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/profile/request/export-token`, {
      password
    });
  }

  getExportData(token: string): Observable<UserExportData> {
    return this.http.get<UserExportData>(`${environment.API_URL}/profiles/profile/export-data/${token}`);
  }

  deleteMyProfile(deleteReason: string, password: string, retain: boolean): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/profiles/user/my`, {
      body: {reason: deleteReason, password, retain},
      params: {
        ignoreSessionRedirect: true
      }
    });
  }

  updateMyNotificationDisturb(disturbEndAt: string | null): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/settings/my/notification/disturb`, {
      disturbEndAt
    });
  }

  getMyNotifications(): Observable<NotificationsInfo> {
    return this.http.get<NotificationsInfo>(`${environment.API_URL}/profiles/settings/my/notification`);
  }

  updateMyNotifications(notifications: {ws: Notifications; email: Notifications}): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/settings/my/notification`, {
      ws: notifications.ws,
      email: notifications.email
    });
  }

  getMyProfileVisibility(resetCache?: boolean): Observable<VisibilitySettings> {
    if (resetCache) {
      delete this.visibilityRequest;
    }
    if (!this.visibilityRequest)
      this.visibilityRequest = this.http.get<Profile>(`${environment.API_URL}/profiles/profile/my`).pipe(
        map((profile) => {
          return {type: profile.visibility};
        }),
        shareReplay(1)
      );
    return this.visibilityRequest;
  }

  updateMyProfileVisibility(type: VisibilityTypes): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/profile/visibility`, {type});
  }

  getMyPrivacySettings(): Observable<PrivacySettings> {
    return this.http.get<PrivacySettings>(`${environment.API_URL}/profiles/settings/my/privacy`).pipe(
      tap((privacySettings) => {
        this.privacySettings = privacySettings;
      })
    );
  }

  updateMyPrivacySettings(params: PrivacySettings): Observable<void> {
    params = {...this.privacySettings, ...params};
    return this.http.put<void>(`${environment.API_URL}/profiles/settings/my/privacy`, params);
  }

  deletePrivateSubscriptions(subscriptionId: string): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/profiles/private-subscriptions/${subscriptionId}`, {});
  }

  getSecuritySettings(): Observable<SecuritySettings> {
    return this.http.get<SecuritySettings>(`${environment.API_URL}/authorization/auth/settings`).pipe(
      tap((securitySettings) => {
        this.securitySettings = securitySettings;
      })
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/authorization/auth/password/edit`, {
      oldPassword,
      newPassword
    });
  }

  updateSecuritySettings(settingField: keyof SecuritySettings, value: any): Observable<void> {
    this.securitySettings[settingField] = value;
    const twoFactorSettings = {type: this.securitySettings.twoFactor};
    return this.http.put<void>(`${environment.API_URL}/authorization/auth/set/two-factor`, {...twoFactorSettings});
  }

  getActiveSessions(): Observable<Session[]> {
    return this.http.get<Session[]>(`${environment.API_URL}/authorization/auth/sessions`);
  }

  deleteActiveSessions(): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/authorization/auth/sessions/terminate`);
  }

  getFanPlans(): Observable<FanPlan[]> {
    return this.http.get<FanPlan[]>(`${environment.API_URL}/profiles/subscriptions/fan-plans`);
  }

  setFanPlan(fanPlan: FanPlan): Observable<void> {
    return this.http.patch<void>(`${environment.API_URL}/profiles/subscriptions/fan-plan`, fanPlan);
  }

  createFanPlan(fanPlan: FanPlan): Observable<FanPlan> {
    return this.http.post<FanPlan>(`${environment.API_URL}/profiles/subscriptions/fan-plan`, fanPlan);
  }

  getNotes(search: string): Observable<Note[]> {
    return this.http
      .get<[{data: Note[]}]>(`${environment.API_URL}/profiles/notes`, {params: {searchString: search}})
      .pipe(
        map(([response]) => {
          response.data.map((noteInfo) => {
            noteInfo.notes = [noteInfo.note];
          });
          return response.data;
        }),
        switchMap((notes) => {
          if (notes.length) {
            const profileIds = notes.map((note) => {
              return (note.relatedProfile as {_id: string}[])[0]?._id;
            });
            const uniqueProfilesIds = Array.from(new Set(profileIds));
            return this.dtProfilesService.getProfilesByIds(uniqueProfilesIds).pipe(
              map((profiles) => {
                notes = notes.map((note) => {
                  note.relatedProfile = profiles.find((profile) => {
                    return profile.profileId === (note.relatedProfile as {_id: string}[])[0]?._id;
                  });
                  return note;
                });
                return notes;
              })
            );
          }
          return of(notes);
        })
      );
  }

  deleteNote(noteId: string): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/profiles/notes/${noteId}`);
  }

  getBlockedUsers(searchQuery: string): Observable<ProfileShort[]> {
    return this.http
      .get<BlockedUsersResponse[]>(`${environment.API_URL}/profiles/blocklist`, {params: {query: searchQuery}})
      .pipe(
        mergeMap(([response]) => {
          const profilesIds = response.data.map((profileInfo) => profileInfo.targetProfile._id);
          if (profilesIds.length) {
            return this.dtProfilesService.getProfilesByIds(profilesIds);
          }
          return of([]);
        })
      );
  }

  updateUserBlock(profileId: string): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/blocklist`, {targetId: profileId});
  }

  clearCache(): void {
    delete this.visibilityRequest;
  }
}
